import React, { useState, useEffect } from "react";
import axios from "axios";
import { API } from "../config";
import { useNavigate } from "react-router-dom";
export default function Component() {
  const [skills, setSkills] = useState([]);
  const [newSkill, setNewSkill] = useState("");
  const [experiences, setExperiences] = useState([
    { company: "", position: "", duration: "" },
  ]);
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({
    firstName: "",
    lastName: "",
    email: "",
    location: "",
    type: "",
    phone: "",
  });
  const [pedding, setPending] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [user, setUser] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    const getUser = async () => {
      try {
        let user = localStorage.getItem("23jobs-user");
        if (!user) {
          navigate("/login");
        }

        user = JSON.parse(user);
        setUser(user);

        await axios.post(
          `${API}/api/users/user`,
          {
            userId: user.id,
            token: user.originToken,
          },
          {
            headers: { Authorization: `Bearer ${user.token}` },
          }
        );

        setInputs({
          firstName: user.firstName,
          lastName: user.lastName,
          location: user.location,
          email: user.email,
          type: user.type,
          phone: user.phone,
        });
      } catch (error) {
        if (error.status === 401) {
          localStorage.removeItem("23jobs-user");
          navigate("/login");
        }
      }
    };
    getUser();
  }, []);

  const addSkill = () => {
    if (newSkill && !skills.includes(newSkill)) {
      setSkills([...skills, newSkill]);
      setNewSkill("");
    }
  };
  const handleSubmit = async () => {
    setPending(true);
    setSuccess(false);
    setError("");
    try {
      const response = await axios.post(
        `${API}/api/users/update`,
        {
          userId: user.id,
          firstName: inputs.firstName,
          lastName: inputs.lastName,
          location: inputs.location,
          type: inputs.type,
          token: user.originToken,
          phone: inputs.phone,
        },
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );

      localStorage.removeItem("23jobs-user");
      localStorage.setItem("23jobs-user", JSON.stringify(response.data.user));

      setSuccess(true);
    } catch (error) {
      if (error.status === 401) {
        navigate("/login");
      } else {
        setError(error?.data?.message);
      }
    }
    setPending(false);
  };

  const removeSkill = (skillToRemove) => {
    setSkills(skills.filter((skill) => skill !== skillToRemove));
  };

  const addExperience = () => {
    setExperiences([
      ...experiences,
      { company: "", position: "", duration: "" },
    ]);
  };

  const updateExperience = (index, field, value) => {
    const updatedExperiences = experiences.map((exp, i) => {
      if (i === index) {
        return { ...exp, [field]: value };
      }
      return exp;
    });
    setExperiences(updatedExperiences);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs({ ...inputs, [name]: value });
  };

  const signOut = async () => {
    localStorage.removeItem("23jobs-user");
    window.location.href = "/";
  };
  const navigateTo = async (url) => {
    navigate(url);
  };

  return (
    <div className="profile-container">
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          padding: "15px 15px 0px 0px",
        }}
        className="space-x-3"
      >
        {user?.type === "employer" ? (
          <button
            className="text-white font-medium px-5 py-2 rounded bg-green-600 hover:bg-green-800"
            onClick={() => navigateTo("/user-jobs")}
          >
            Your jobs
          </button>
        ) : (
          <button
            className="text-white font-medium px-5 py-2 rounded bg-green-600 hover:bg-green-800"
            onClick={() => navigateTo("/user-jobrequests")}
          >
            Your job requests
          </button>
        )}
        <button
          className="pricing-plan-button"
          onClick={() => signOut()}
          style={{ background: "red" }}
        >
          Sign out
        </button>
      </div>
      <div className="profile-header" style={{ marginTop: "-40px" }}>
        <h2 className="profile-title">Update Your Profile</h2>
        <p className="profile-description">
          Keep your professional information up to date to find the best job
          opportunities.
        </p>
      </div>
      <div className="profile-content">
        <div className="form-group">
          <div className="names">
            <div>
              <label htmlFor="firstName" className="form-label">
                First Name
              </label>
              <input
                id="firstName"
                name="firstName"
                placeholder="John"
                className="form-input"
                value={inputs.firstName}
                onChange={handleChange}
              />
            </div>
            <div>
              <label htmlFor="lastName" className="form-label">
                Last Name
              </label>
              <input
                id="lastName"
                name="lastName"
                placeholder="Doe"
                className="form-input"
                value={inputs.lastName}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            id="email"
            type="email"
            placeholder="johndoe@example.com"
            className="form-input"
            value={inputs.email}
            disabled={true}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone" className="form-label">
            Phone
          </label>
          <input
            id="phone"
            type="phone"
            placeholder="Phone"
            className="form-input"
            value={inputs.phone}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="location" className="form-label">
            Location
          </label>
          <input
            id="location"
            name="location"
            placeholder="City, Country"
            className="form-input"
            value={inputs.location}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="type" className="form-label">
            Role
          </label>
          <select
            id="type"
            name="type"
            className="form-input"
            value={inputs.type}
            onChange={handleChange}
            required
          >
            <option value="employee">Employee</option>
            <option value="employer">Employer</option>
          </select>
        </div>
        {/* <div className="form-group">
                  <label htmlFor="summary" className="form-label">
                    Professional Summary
                  </label>
                  <textarea
                    id="summary"
                    placeholder="Brief overview of your professional background and career goals"
                    className="form-textarea min-h-[100px]"
                  ></textarea>
                </div>
                <div className="form-group">
                  <label htmlFor="skills" className="form-label">
                    Skills
                  </label>
                  <div className="skill-badges">
                    {skills.map((skill, index) => (
                      <div key={index} className="skill-badge">
                        {skill}
                        <button onClick={() => removeSkill(skill)} className="skill-badge__remove">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="h-3 w-3"
                          >
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                          </svg>
                        </button>
                      </div>
                    ))}
                  </div>
                  <div className="skill-input">
                    <input
                      id="skills"
                      value={newSkill}
                      onChange={(e) => setNewSkill(e.target.value)}
                      placeholder="Add a skill"
                      onKeyPress={(e) => e.key === 'Enter' && addSkill()}
                      className="form-input"
                    />
                    <button onClick={addSkill} type="button" className="form-button">
                      Add
                    </button>
                  </div>
                </div>
                <div className="form-group">
                  <h3 className="form-group__title">Work Experience</h3>
                  {experiences.map((exp, index) => (
                    <div key={index} className="experience-card">
                      <div className="experience-card__content">
                        <input
                          placeholder="Company"
                          value={exp.company}
                          onChange={(e) => updateExperience(index, 'company', e.target.value)}
                          className="form-input"
                        />
                        <input
                          placeholder="Position"
                          value={exp.position}
                          onChange={(e) => updateExperience(index, 'position', e.target.value)}
                          className="form-input"
                        />
                        <input
                          placeholder="Duration (e.g., Jan 2020 - Present)"
                          value={exp.duration}
                          onChange={(e) => updateExperience(index, 'duration', e.target.value)}
                          className="form-input"
                        />
                      </div>
                    </div>
                  ))}
                  <button onClick={addExperience} type="button" className="form-button add-experience-button">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="plus_button"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                    </svg>
                    Add Experience
                  </button>
                </div>
                <div className="form-group">
                  <label htmlFor="education" className="form-label">
                    Education
                  </label>
                  <input id="education" placeholder="Degree, Major - University" className="form-input" />
                </div> */}
      </div>
      <div className="profile-footer">
        <button
          className="form-button save-button"
          disabled={pedding}
          onClick={handleSubmit}
        >
          Save Profile
        </button>

        {success && (
          <p style={{ color: "green" }}>User updated successfully!</p>
        )}
        {error && (
          <p style={{ color: "red" }}>An error occurred. Please try again</p>
        )}
      </div>
    </div>
  );
}
