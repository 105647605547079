// JobCards.js
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import axios from "axios";
import { API } from "../config";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import SocialShare from "./SocialShare";
const UserJobsCard = (props) => {
  const [share, setShare] = useState(false);
  const [del, setDel] = useState(false);
  const [pedding, setPending] = useState(false);
  const navigate = useNavigate();
  const deleteJob = async () => {
    setPending(true);
    try {
      let user = localStorage.getItem("23jobs-user");
      if (!user) {
        toast.error("Access denied. You are not authenticated!");
        navigate("/login");
      }
      user = JSON.parse(user);

      if (user.id === props.job.userId) {
        await axios.post(
          `${API}/api/job/delete/${props.job.id}`,
          {
            userId: user.id,
            token: user.originToken,
          },
          {
            headers: { Authorization: `Bearer ${user.token}` },
          }
        );
        window.location.reload();
      } else {
        toast.error("You are not authorized to delete this job");
      }
    } catch (error) {
      console.log("error: ", error);
      toast.error(error?.response.data?.message);
      // navigate("/login");
    }
    setPending(false);
  };

  return (
    <div className="job-card">
      <h3 className="job-title">{props.job.title}</h3>
      <p className="job-description truncteDescription">
        {props.job.description}
      </p>
      <div className="card-actions">
        <div style={{ gap: "10px" }} className="justify-between ">
          <a href={`/job-details/${props.job.id}`} className="view-button mr-2">
            View
          </a>
          <a
            href={props.viewLink}
            className="view-button mr-2"
            onClick={() => setDel(true)}
          >
            Delete
          </a>
          <Link to={`/edit-job/${props.job.id}`} className="view-button">
            Edit
          </Link>
        </div>
        <button className="share-button">
          <span onClick={() => setShare(!share)}>
            <FontAwesomeIcon icon={faShareAlt} />
          </span>
        </button>
      </div>
      {del && (
        <div style={{ marginTop: "20px", color: "red" }}>
          <div>Are you sure you want to delete this job?</div>
          <div style={{ marginTop: "10px" }}>
            <a
              href={props.viewLink}
              className="view-button"
              style={{ background: "red" }}
              onClick={() => deleteJob()}
              disabled={pedding}
            >
              Yes
            </a>
            <a
              href={props.viewLink}
              className="view-button"
              onClick={() => setDel(false)}
            >
              No
            </a>
          </div>
        </div>
      )}

      {share && (
        <div className="pt-5">
          <SocialShare
            url={`https://237jobs.com/job-details/${props.job.id}`}
          />
        </div>
      )}
    </div>
  );
};

export default UserJobsCard;
