// Reset Password React Component
import React, { useState, useEffect } from "react";
import axios from "axios";
import { API } from "../config";
const NewToken = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [pending, setPending] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPending(true);
    setError("");
    setSuccess(false);
    try {
      await axios.get(`${API}/api/users/new-token/${email}`);
      setSuccess(true);
    } catch (error) {
      setError(error?.response?.data.message);
    }
    setPending(false);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="reset-password-container">
      <div className="reset-password-card">
        <h2>New token</h2> <br></br>
        <br></br>
        <form className="reset-password-form" onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button
            disabled={pending}
            className="verifyemail"
            style={{ width: "100%" }}
          >
            Request new token
          </button>
          {success ? (
            <p style={{ color: "green" }}>
              Please checkout your email acount. A new verification link sent!
            </p>
          ) : (
            <p style={{ color: "red" }}>{error}</p>
          )}
        </form>
      </div>
    </div>
  );
};

export default NewToken;
