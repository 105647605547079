import React from 'react';
import Intropage from './Intropage';

const Layout = ({ title, children }) => {
  return (
    <div>
      <Intropage title={title} />
      <main>{children}</main>
    </div>
  );
};

export default Layout;