import { configureStore } from "@reduxjs/toolkit";
import { jobSlice } from "./feature/job/jobSlice";
export default configureStore({
  reducer: {
    job: jobSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
