import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { API } from "../config";

const RequestedJobsSection = () => {
  const [jobRequest, setJobRequest] = useState([]);

  useEffect(() => {
    const listRequestedJobs = async () => {
      try {
        const jobRequest = await axios.get(`${API}/api/job-requests/list`);
        setJobRequest(jobRequest.data.data);
      } catch (error) {
        console.log("error: ", error.response);
      }
    };
    listRequestedJobs();
  }, []);

  return (
    <section className="testimonial-section">
      <h2 className="about-us-heading">See all requested jobs from people</h2>
      <br></br>

      <div className="testimonial-container">
        {jobRequest.length > 0 ? (
          jobRequest.map((job) => (
            <div className="testimonial-card " key={job.id}>
              <h3 className="testimonial-name" style={{ color: "green" }}>
                {job?.user?.firstName || "N/A"} {job?.user?.lastName || "N/A"}
              </h3>
              <p className="text-black">{job.title}</p>
              <p className="text-black">Email: {job?.user?.email}</p>
              <p className="text-black">Location: {job.location}</p>
              <p className="testimonial-text text-black">{job.description}</p>
            </div>
          ))
        ) : (
          <p>No active job requests found.</p>
        )}
      </div>

      <br></br>
      <Link to="/request-job" target="_blank">
        <button className="see-more-link" style={{ textAlign: "center" }}>
          Request Job
        </button>
      </Link>
    </section>
  );
};

export default RequestedJobsSection;
