import React, { useState, useEffect } from "react";
import JobComponent from "../components/JobComponent";
import IntroSection from "../components/Intropage";
import { BiSearch } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { searchJobs } from "../redux-store/feature/job/jobSlice";
const MoreJobs = () => {
  const dispatch = useDispatch();
  const jobs = useSelector((state) => state.job.jobs);
  const page = useSelector((state) => state.job.page);
  const pages = useSelector((state) => state.job.pages);
  const limit = useSelector((state) => state.job.limit);
  const [query, serQuery] = useState("");

  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  // }, []);

  useEffect(() => {

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    const listJobs = async () => {
      if (query) {
        dispatch(searchJobs({ query, limit: 12, page: 1 }));
      } else {
        dispatch(searchJobs({ limit: 12, page: 1 }));
      }
    };
    listJobs();
  }, []);

  const handleSearch = (value) => {
    if (value !== "") {
      dispatch(searchJobs({ query, limit: 12, page: 1 }));
    } else {
      dispatch(searchJobs({ limit: 12, page: 1 }));
    }
  };
  const loadJobs = (page) => {
    if (query !== "") {
      dispatch(searchJobs({ query, limit: 12, page }));
    } else {
      dispatch(searchJobs({ limit: 12, page }));
    }
  };

  return (
    <div>
      <IntroSection title={`Available Jobs`}>
        <div
          style={{ display: "flex", width: "100%", justifyContent: "center" }}
        >
          <div className="search-container">
            <input
              type="text"
              placeholder="Search..."
              value={query}
              onChange={(e) => {
                serQuery(e.target.value);
                handleSearch(e.target.value);
              }}
            />

            <button onClick={() => handleSearch()}>
              <BiSearch />
            </button>
          </div>
        </div>
      </IntroSection>
      <section className="job-cards-section">
        <div className="md:px-4 w-full">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            <JobComponent jobs={jobs} />
          </div>
        </div>

        {jobs.length === 0 ? (
          <div style={{ textAlign: "center", width: "100%" }}>
            Search not found
          </div>
        ) : (
          <div className="mt-[5rem] w-full flex items-center justify-center">
            <button
              disabled={page <= 1}
              onClick={() => loadJobs(page - 1)}
              style={{ marginRight: "1rem" }}
              class="flex items-center hover:cursor-pointer py-2 px-3 rounded font-medium select-none border text-gray-900 bg-white transition-colors hover:border-green-600 hover:bg-green-600 hover:text-white"
            >
              ⪻ Previous
            </button>
            <div>
              {" "}
              {page} / {pages}
            </div>
            <button
              disabled={page >= pages}
              onClick={() => loadJobs(page + 1)}
              class="flex items-center py-2 ml-4 hover:cursor-pointer px-3 rounded font-medium select-none border text-gray-900  bg-white transition-colors hover:border-green-600 hover:bg-green-600 hover:text-white "
            >
              Next ⪼
            </button>
          </div>
        )}
      </section>
    </div>
  );
};

export default MoreJobs;
