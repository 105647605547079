import React, { useState, useEffect } from "react";
import Introlayout from "../components/Introlayout";
import axios from "axios";
import { API } from "../config";
import { useNavigate } from "react-router-dom";
import UserJobRequestCard from "../components/UserJobRequestCard";
import { toast } from "react-toastify";
export default function UserJobRequests() {
  const [user, setUser] = useState(null);
  const [jobRequests, setJobs] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    const getUser = async () => {
      try {
        let user = localStorage.getItem("23jobs-user");
        if (!user) {
          toast.error("Access denied. You are not authenticated!");
          navigate("/login");
        }
        user = JSON.parse(user);
        setUser(user);

        try {
          await axios.post(
            `${API}/api/users/user`,
            {
              userId: user.id,
              token: user.originToken,
            },
            {
              headers: { Authorization: `Bearer ${user.token}` },
            }
          );
        } catch (error) {
          toast.error("Access denied. You are not authenticated!");
          navigate("/login");
        }

        if (user.type !== "employee") {
          toast.info("Only employees can access this page.");
          navigate("/");
        }

        console.log("user.id: ", user.id);

        const response = await axios.post(
          `${API}/api/job-requests/user-requests`,
          {
            userId: user.id,
            token: user.originToken,
          },
          {
            headers: { Authorization: `Bearer ${user.token}` },
          }
        );

        console.log("response: ", response);

        setJobs(response.data);
      } catch (error) {
        console.log("error: ", error);
      }
    };
    getUser();
  }, []);

  return (
    <div>
      <Introlayout title="Your Job Requests"></Introlayout>

      <section className="job-cards-section w-full">
        <div className="md:px-4 w-full">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            {jobRequests.map((jobRequest) => (
              <UserJobRequestCard key={jobRequest.id} jobRequest={jobRequest} />
            ))}
          </div>
        </div>
        <div className="job-cards-container w-full">
          {jobRequests.length === 0 && (
            <div className="flex justify-center w-full">
              <p className="text-center">You have no available job requests</p>
            </div>
          )}
        </div>
        <br></br>
      </section>
    </div>
  );
}
