import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faWhatsapp,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import {
  faPhone,
  faEnvelope,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <footer class="bg-[#008000]">
      <div class="mx-auto w-full ">
        <div class="grid grid-cols-2 gap-8 px-4 py-6 lg:py-8 md:grid-cols-4">
          <div>
            <h2 class="mb-6 text-lg font-semibold text-[#fffc03]  text-white-900 uppercase">
              Our Objective
            </h2>
            <ul class="text-white-500 font-medium">
              <li class="mb-4">
                <p class="text-white ">
                We are committed to providing the best available Jobs in cameroon, we connect employers with job seekers and Job seekers with possible employers. Our goal is to eradicate joblessness and unemployement.
                </p>
              </li>
            </ul>
          </div>
          <div>
            <h2 class="mb-6 text-lg font-semibold text-[#fffc03] uppercase">
              Quiuck Links
            </h2>
            <ul class="text-white-500 font-medium">
              <li class="mb-4">
                <Link to="/" class="hover:underline text-white ">
                Home
                </Link>
              </li>
              <li class="mb-4">
                <Link to="/about" class="hover:underline text-white">
                  About Us
                </Link >
              </li>
              <li class="mb-4">
                <Link to="/dashboard" class="hover:underline text-white">
                  My Account
                </Link>
              </li>
              <li class="mb-4">
                <Link to="/jobs" class="hover:underline text-white ">
                    Job Listings
                </Link>
              </li>
              <li class="mb-4">                
                <Link to="/requested-jobs" class="hover:underline text-white">
                Job Request
                </Link>
                </li>
            </ul>
          </div>

          <div>
            <h2 class="mb-6 text-lg font-semibold text-white-900 uppercase text-[#fffc03]">
            Contact Us
            </h2>
            <ul class="text-white-500 font-medium text-white">
              <li class="mb-4">
                <p>(+237) 680008268 </p>
                 <p> (+237) 680720951
                </p>
                <p>237jobs1@gmail.com</p>
                <p>Buea, Cameroon</p>

              </li>
              <li class="mb-4 text-white ">
              <Link to="#" className="social-icon text-white">
                <FontAwesomeIcon icon={faFacebook} />
              </Link>

             <Link to="#" className="social-icon text-white ">
              <FontAwesomeIcon icon={faInstagram} />
            </Link>
            <Link to="#" className="social-icon text-white ">
                 <FontAwesomeIcon icon={faWhatsapp} />
              </Link>
              <Link to="#" className="social-icon text-white">
                 <FontAwesomeIcon icon={faLinkedin} />
             </Link>
              </li>
            </ul>
          </div>
          <div>
            <h2 class="mb-6 text-lg font-semibold text-white-900 uppercase text-[#fffc03]">
            Get Started
            </h2>
            <ul class="text-white-500 font-medium">
              <li class="mb-4">
              <Link to="/Signup" class="hover:underline text-white">Creat An Account</Link>
              </li>
              <li class="mb-4">
              <Link to={`/postjobs`} class="hover:underline text-white">Post Job</Link>
              </li>
              <li class="mb-4">
              <Link to="/request-job" class="hover:underline text-white ">Request Job</Link>
              </li>
              <li class="mb-4">
              <Link to="/ContactUs" class="hover:underline text-white">Contact Us</Link>
              </li>
            </ul>
          </div>
        </div>
        <div class="px-4 flex justify-center items-center py-6 bg-white-500 w-full ">
          <div className="">
            <span class="text-sm text-white-500 sm:text-center text-white">
              © {new Date().getFullYear()}{" "}
              <a href="#" class='text-[#fffc03]'>Designed & Developed By Terminal Trend Technology</a>. All Rights
              Reserved.
            </span>
          </div>
        </div>
      </div>
    </footer>

  );
};

export default Footer;
