import React, { useState, useEffect } from "react";
import axios from "axios";
import { API } from "../config";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
const LoginPage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const [pending, setPending] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async () => {
    // Implement login logic here
    setPending(true);
    setError("");

    try {
      const result = await axios.post(`${API}/api/users/signin`, {
        email,
        password,
      });
      localStorage.removeItem("23jobs-user");
      localStorage.setItem("23jobs-user", JSON.stringify(result.data.user));
      window.location.href = "/";
    } catch (error) {
      console.log("error: ", error);
      setError(error?.response?.data.message);
    }
    setPending(false);
  };

  return (
    <div className="login-container">
      <form className="login-form">
        <h2 className="login-title">Login</h2>
        <div className="form-group">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            type="text"
            id="email"
            className="form-input"
            value={email}
            onChange={handleEmailChange}
            placeholder="Enter your email or username"
          />
        </div>
        <div className="form-group">
          <label htmlFor="password" className="form-label">
            Password
          </label>
          <div className="password-input-wrapper">
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              className="form-input"
              value={password}
              onChange={handlePasswordChange}
              placeholder="Enter your password"
            />
            <div
              className={`password-toggle-icon ${showPassword ? "show" : ""}`}
              onClick={handlePasswordToggle}
            >
              {showPassword ? "Hide" : "Show"}
            </div>
          </div>
        </div>
        <button
          disabled={pending}
          type="button"
          className="login-button flex justify-center"
          onClick={handleLogin}
        >
          {pending ? <Loader /> : <span>Login</span>}
        </button>
        {error && <p style={{ color: "red" }}>{error}</p>}

        <br></br>
        <div className="login-footer">
          <div className="login-p">
            <p>
              Don't have an account?
              <a href="/Signup" className="create-account-link">
                Create an account
              </a>
            </p>
            <p>
              <a href="/Resetpassword" className="create-account-link">
                Forget Password
              </a>
            </p>
          </div>
        </div>
      </form>
    </div>
  );
};

export default LoginPage;
