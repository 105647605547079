import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getJobs } from "../redux-store/feature/job/jobSlice";
import JobComponent from "./JobComponent";
const JobCards = () => {
  const jobs = useSelector((state) => state.job.jobs);
  const dispatch = useDispatch();

  useEffect(() => {
    const listJobs = async () => {
      dispatch(getJobs());
    };
    listJobs();
  }, [dispatch]);

  return (
    <section id="jobs-section" className="job-cards-section">
      <h1 className="about-us-heading">Available Jobs</h1>
      {jobs.length === 0 ? (
        <p className="testimony-text">No available jobs</p>
      ) : (
        <p className="testimony-text">See all the available jobs</p>
      )}

      <br></br>
      <div className="md:px-4 w-full">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          <JobComponent jobs={jobs.slice(0, 12)} />
        </div>
      </div>
      <br></br>

      {jobs.length !== 0 && (
        <Link to="/jobs">
          <button className="see-more-link">See more</button>
        </Link>
      )}
    </section>
  );
};

export default JobCards;
