import React, { useState, useEffect } from "react";
import Introlayout from "../components/Introlayout";
import axios from "axios";
import { API } from "../config";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
export default function RequestJob() {
  const [user, setUser] = useState(null);
  const [title, setJobTitle] = useState("");
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [pending, setPending] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    const getUser = async () => {
      try {
        let user = localStorage.getItem("23jobs-user");
        if (!user) {
          toast.info("You are not authenticated. Please sign in!");
          navigate("/login");
        }
        user = JSON.parse(user);
        setUser(user);

        if (user.type !== "employee") {
          toast.info("Only employees are allowed to request jobs.");
          navigate("/");
        }
      } catch (error) {
        console.log("error: ", error);
      }
    };
    getUser();
  }, []);

  const createJob = async () => {
    setPending(true);
    try {
      const response = await axios.post(
        `${API}/api/job-requests/create`,
        {
          userId: user.id,
          title,
          location,
          description,
          token: user.originToken,
        },
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
      toast.success("Job request has been successfully created!");
      navigate("/requested-jobs");
    } catch (error) {
      console.log("error: ", error.response);
      toast.error(error?.response.data?.message);
    }
    setPending(false);
  };

  return (
    <div>
      <Introlayout title="Request for a job"></Introlayout>
      <div className="job-posting-container">
        <div className="job-posting-form-preview">
          {/* Job Posting Form */}
          <div className="job-posting-form">
            <div className="form-group">
              <label htmlFor="title" className="form-label">
                Job Title
              </label>
              <input
                id="title"
                placeholder="e.g. Senior React Developer"
                value={title}
                onChange={(e) => setJobTitle(e.target.value)}
                className="form-input"
              />
            </div>

            <div className="form-group">
              <label htmlFor="location" className="form-label">
                Location
              </label>
              <input
                id="location"
                placeholder="e.g. New York, NY"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                className="form-input"
              />
            </div>

            <div className="form-group">
              <label htmlFor="description" className="form-label">
                Job Description
              </label>
              <textarea
                id="description"
                placeholder="Describe the job role, responsibilities, and requirements..."
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="form-textarea"
              ></textarea>
            </div>
            <button
              className="form-button"
              disabled={pending}
              onClick={() => createJob()}
            >
              Request Job
            </button>
          </div>

          {/* Job Posting Preview */}
          <div className="job-posting-preview">
            <h2 className="job-posting-preview-title">
              {title || "Job Title"}
            </h2>
            <div className="job-posting-preview-info">
              <div className="job-posting-preview-info-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="job-posting-preview-icon"
                >
                  <path d="M20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z" />
                  <circle cx="12" cy="10" r="3" />
                </svg>
                <span>{location || "Job Location"}</span>
              </div>
            </div>
            <div className="job-posting-preview-description">
              <h3 className="job-posting-preview-description-title">
                Job Description
              </h3>
              <p className="job-posting-preview-description-text">
                {description || "Job description will appear here..."}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
