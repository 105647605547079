import React, { useState, useEffect } from "react";
import Introlayout from "../components/Introlayout";
import axios from "axios";
import { API } from "../config";
import { useNavigate } from "react-router-dom";
import UserJobsCard from "../components/UserJobsCard";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
export default function YourApplications() {
  const [user, setUser] = useState(null);
  const [applications, setApplications] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    const getUser = async () => {
      try {
        let user = localStorage.getItem("23jobs-user");
        if (!user) {
          toast.error("Access denied. You are not authenticated!");
          navigate("/login");
        }
        user = JSON.parse(user);
        setUser(user);
        const apps = await axios.get(
          `${API}/api/job-applications/applications/${user.id}`
        );
        setApplications(apps.data);
      } catch (error) {
        console.log("error: ", error);
      }
    };
    getUser();
  }, []);

  return (
    <div>
      <Introlayout title="Your Job Applications"></Introlayout>

      <section className="job-cards-section">
        <div className="md:px-4 w-full">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            {applications.map((app) => (
              <div key={app} className="job-card">
                <h3 className="job-title">{app?.job.title}</h3>
                <p className="job-description">{app?.job.description}</p>
              </div>
            ))}
          </div>
        </div>

        <br></br>
      </section>
    </div>
  );
}

/***
 * change color or moment
 * update share button
 *
 */
