import React from 'react';
import partner1 from '../assets/partner1.jpg';
import partner2 from '../assets/partner2.jpg';
import partner3 from '../assets/partner3.jpg';
import partner4 from '../assets/partner4.jpg';
import partner5 from '../assets/partner5.jpg';
import partner6 from '../assets/partner6.jpg';



const PartnerSection = () => {
  return (
    <section className="partner-section">
      <div className="partner-content">
        <h1>Our Trusted Partners</h1>
        <p className="subheading">We collaborate with industry-leading companies to provide the best jobs.</p>
        <div className="partner-logos">
          <img src={partner1} alt="Partner 1" />
          <img src={partner2} alt="Partner 2" />
          <img src={partner3} alt="Partner 3" />
          <img src={partner4} alt="Partner 4" />
          <img src={partner5} alt="Partner 5" />
          <img src={partner6} alt="Partner 6" />
        </div>
      </div>
    </section>
  );
};

export default PartnerSection;