// Reset Password React Component
import React, { useState, useEffect } from "react";
import axios from "axios";
import { API } from "../config";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
const NewPassword = () => {
  const { token } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [pending, setPending] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setPending(true);
    setError("");
    if (password !== confirmPassword) {
      setError("Password and confirm password are not the same");
      return;
    }
    try {
      await axios.post(`${API}/api/users/reset-password`, {
        newPassword: password,
        token,
      });

      toast.success("Your password has been reseted. You can now login!");

      navigate("/login");
    } catch (error) {
      console.log("error: ", error);
      setError(error?.response?.data?.message);
    }
    setPending(false);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="reset-password-container">
      <div className="reset-password-card">
        <h2>Reset Password</h2>
        <br />
        <form className="reset-password-form" onSubmit={handleSubmit}>
          <input
            type="password"
            placeholder="Enter new password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Re-enter password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <button type="submit" disabled={pending}>
            {pending ? <Loader /> : <span>Reset password</span>}
          </button>
          {error && <p style={{ color: "red" }}>{error}</p>}
        </form>
      </div>
    </div>
  );
};

export default NewPassword;
