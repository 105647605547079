import axios from "axios";
import { API } from "../../../config";
const getJobs = async () => {
  try {
    const result = await axios.get(`${API}/api/job/jobs`);
    return { success: true, jobs: result.data };
  } catch (error) {
    return { success: false, error: error };
  }
};
const searchJobs = async (query) => {
  try {
    const result = await axios.post(`${API}/api/job/search`, {
      title: query?.title,
      jobType: query?.jobType,
      location: query?.location,
      query: query?.query,
      limit: query?.limit,
      page: query?.page,
    });

    console.log("result: ", result);

    return { success: true, jobs: result.data };
  } catch (error) {
    console.log("result: ", query, error);
    return { success: false, error: error };
  }
};

const jobService = {
  getJobs,
  searchJobs,
};
export default jobService;
