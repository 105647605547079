import React, { useState, useEffect } from "react";
import Introlayout from "../components/Introlayout";
import axios from "axios";
import { API } from "../config";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
export default function JobApplication() {
  const [user, setUser] = useState("");
  const [title, setJobTitle] = useState("");
  const [company, setCompany] = useState("");
  const [location, setLocation] = useState("");
  const [salary, setSalary] = useState("");
  const [description, setDescription] = useState("");
  const [jobType, setJobType] = useState("");
  const [employmentType, setEmploymentType] = useState("");
  const [status, setStatus] = useState("active");
  const [duration, setDuration] = useState("");
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState("");
  const [pending, setPending] = useState(false);
  const { id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    const getUser = async () => {
      try {
        let user = localStorage.getItem("23jobs-user");
        if (!user) {
          navigate("/login");
        }
        user = JSON.parse(user);
        setUser(user);

        const response = await axios.post(
          `${API}/api/job`,
          {
            id: id,
            userId: user.id,
            token: user.originToken,
          },
          {
            headers: { Authorization: `Bearer ${user.token}` },
          }
        );

        setJobTitle(response.data.title);
        setCompany(response.data.company);
        setLocation(response.data.location);
        setSalary(response.data.salary);
        setDescription(response.data.description);
        setJobType(response.data.jobType);
        setEmploymentType(response.data.employmentType);
        setStatus(response.data.status);
        setDuration(response.data.duration);
      } catch (error) {
        console.log("error: ", error);
      }
    };
    getUser();
  }, []);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const Apply = async () => {
    setPending(true);

    if (!file) {
      toast.warn("Please select a file");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        `${API}/api/file/upload-resume`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log("response2: ", response);
      const response2 = await axios.post(
        `${API}/api/job-applications/create`,
        {
          userId: user.id,
          token: user.originToken,
          jobId: parseInt(id),
          resume: response.data.filePath,
          status: "Pending",
        },
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );

      toast.success("You have successfully applied to this job!");
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.log("response2: ", error);
    }

    setPending(false);
  };

  return (
    <div>
      <Introlayout title="Job application"></Introlayout>
      <div className="job-posting-container">
        <div className="job-posting-form-preview">
          {/* Job Posting Form */}
          <div className="job-posting-form">
            <div className="form-group">
              <label htmlFor="duration" className="form-label">
                Resume
              </label>
              <input
                type="file"
                accept="application/pdf"
                onChange={handleFileChange}
              />
            </div>

            <button
              className="form-button"
              disabled={pending}
              onClick={() => Apply()}
            >
              Apply
            </button>
          </div>

          {/* Job Posting Preview */}
          <div className="job-posting-preview">
            <h2 className="job-posting-preview-title">
              {title || "Job Title"}
            </h2>
            <div className="job-posting-preview-info">
              <div className="job-posting-preview-info-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="job-posting-preview-icon"
                >
                  <rect width="20" height="14" x="2" y="7" rx="2" ry="2" />
                  <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16" />
                </svg>
                <span>{company || "Company Name"}</span>
              </div>
              <div className="job-posting-preview-info-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="job-posting-preview-icon"
                >
                  <path d="M20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z" />
                  <circle cx="12" cy="10" r="3" />
                </svg>
                <span>{location || "Job Location"}</span>
              </div>
              <div className="job-posting-preview-info-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="job-posting-preview-icon"
                >
                  <rect width="18" height="18" x="3" y="4" rx="2" ry="2" />
                  <line x1="16" x2="16" y1="2" y2="6" />
                  <line x1="8" x2="8" y1="2" y2="6" />
                  <line x1="3" x2="21" y1="10" y2="10" />
                  <path d="m9 16 2 2 4-4" />
                </svg>
                <span>{jobType || "Job Type"}</span>
              </div>
              <div className="job-posting-preview-info-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="job-posting-preview-icon"
                >
                  <path d="M22 12h-4l-3 9L9 3l-3 9H2" />
                </svg>
                <span>{employmentType || "Employment Type"}</span>
              </div>
              <div className="job-posting-preview-info-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="job-posting-preview-icon"
                >
                  <circle cx="12" cy="12" r="10" />
                  <path d="M12 2v20" />
                  <path d="M12 12h8.5" />
                  <path d="m8.5 8.5-7 7" />
                  <path d="m8.5 15.5 7-7" />
                </svg>
                <span>{salary || "Salary Range"}</span>
              </div>
            </div>
            <div className="job-posting-preview-description">
              <h3 className="job-posting-preview-description-title">
                Job Description
              </h3>
              <p className="job-posting-preview-description-text">
                {description || "Job description will appear here..."}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
