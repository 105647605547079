import React from 'react';
import { Link } from 'react-router-dom';
import appstore from '../assets/appstore.jpg';
import playstore from '../assets/playstore.jpg';

const UpcomingAppAwareness = () => (
  <section className="upcoming-app-awareness">
    <div className="app-awareness-container">
      <div className="app-awareness-content">
        <h2 className="app-awareness-header">Use our App</h2>
        <div className="app-awareness-features">
          <div className="feature-item">
            <h3>Post Jobs</h3>
            <p>
              1. Use our app to post job opportunities.
            </p>
            <p>2. Gain access to Unlimited Job Listings.</p>
            <p>3. connect with thousands of potential employees.</p>
          </div>
          <div className="feature-item">
            <h3>Request Jobs</h3>
            <p> 1. Request Jobs and Stand a chance to be recuitd by potential employers. </p>
            <p>2. Post a job request.</p>
            <p>3. Apply for Jobs.</p>
          </div>
        </div>
        <div className="app-download-section">
          <p>Get the app from:</p>
          <div className="app-download-buttons">
            <Link to="#">
              <img src={playstore} alt="Google Play" />
            </Link>
            <Link to="#">
              <img src={appstore} alt="App Store" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default UpcomingAppAwareness;