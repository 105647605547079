import React, { useEffect } from "react";
import logo from "../assets/logo.jpg";
import Testimonial from "../components/Testimonial";
import Partners from "../components/Partners";
import Introlayout from "../components/Introlayout";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Introlayout title="about us"></Introlayout>
      <div className="about-us-page">
        <div className="about-us-container">
          <div className="about-us-text">
            <h2 className="about-us-subtitle">WHO WE ARE</h2>
            <p className="about-us-description">
              We are a team of passionate individuals dedicated to providing
              exceptional job Listing opportunities in cameroon to our Users. We strive to eradicate joblessness and unemployement.
            
            </p>
            <h2 className="about-us-subtitle">OUR MISSION</h2>
            <p className="about-us-description">
              Our mission is to eradicate joblessness and unemployement by connecting talented individuals with the most
              exciting job opportunities in Cameroon. We believe that
              unemployement is the driving force behind joblessness, and we're dedicated to helping people find their dream
              jobs in this dynamic ecosystem.
            </p>
            <h2 className="about-us-subtitle">WHAT WE DO</h2>
            <p className="about-us-description">
              At our Cameroon jobs seeking site, our mission is to connect
              talented individuals with meaningful employment opportunities
              across the country. We understand the challenges job seekers face
              in today's competitive market, which is why we have dedicated
              ourselves to building a comprehensive platform that simplifies the
              job search process. Our core focus is to provide job seekers in
              Cameroon with access to a wide range of job listings from
              reputable employers in various industries. We curate these job
              postings carefully, ensuring that they align with the skills and
              aspirations of our users. Whether you're a recent graduate, an
              experienced professional, or someone seeking a career change, our
              site is designed to be your one-stop destination for finding your
              dream job.
            </p>
          </div>
          <div className="about-us-image-container">
            <img src={logo} alt="About Us" className="about-us-image" />
          </div>
        </div>
      </div>

      <Testimonial />
      <Partners />
    </>
  );
};

export default AboutUs;
