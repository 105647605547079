// Reset Password React Component
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { API } from "../config";
const VerifyEmail = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    const verifyAccount = async () => {
      try {
        await axios.get(`${API}/api/users/verify-email/${token}`);
        setSuccess(true);
      } catch (error) {
        console.log("error: ", error);
      }

      setIsLoading(false);
    };
    verifyAccount();
  }, [token]);

  const handleClick = () => {
    navigate("/login");
  };
  const requestToken = async () => {
    navigate("/new-token");
  };

  return (
    <div className="reset-password-container">
      <div className="reset-password-card">
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
                opacity=".25"
              />
              <path
                fill="currentColor"
                d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"
              >
                <animateTransform
                  attributeName="transform"
                  dur="0.75s"
                  repeatCount="indefinite"
                  type="rotate"
                  values="0 12 12;360 12 12"
                />
              </path>
            </svg>
          </div>
        ) : (
          <div className="">
            {success ? (
              <>
                <p style={{ color: "green" }}>Email successfully verified </p>
                <button
                  type="submit"
                  className="verifyemail"
                  style={{ width: "100%" }}
                  onClick={() => handleClick()}
                >
                  Back to login
                </button>
              </>
            ) : (
              <>
                <p style={{ color: "red" }}>Invalid or expired token</p>
                <button
                  type="submit"
                  className="verifyemail"
                  style={{ width: "100%" }}
                  onClick={() => requestToken()}
                >
                  Request new token
                </button>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default VerifyEmail;
