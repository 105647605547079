// CreateAccountPage.js
import React, { useState, useEffect } from "react";
import Introlayout from "../components/Introlayout";
import axios from "axios";
import { API } from "../config";
import Loader from "../components/Loader";

const CreateAccountPage = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [location, setLocation] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [type, setType] = useState("employee");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [pending, setPending] = useState(false);
  const [phone, setPhone] = useState("");
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle form submission, e.g., send data to server

    setPending(true);
    setError(false);
    setSuccess(false);
    try {
      await axios.post(`${API}/api/users/signup`, {
        firstName,
        lastName,
        email,
        location,
        password,
        type,
        phone,
      });
      setSuccess(true);
    } catch (error) {
      setError(true);
    }
    setPending(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div>
      <Introlayout title="be part of us"></Introlayout>
      <div className="create-account-container h-fit">
        <form onSubmit={handleSubmit} className="create-account-form">
          <h2 className="create-account-title">Create an Account</h2>
          <div className="form-group">
            <label htmlFor="firstName" className="form-label">
              First Name
            </label>
            <input
              type="text"
              id="firstName"
              className="form-input"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="lastName" className="form-label">
              Last Name
            </label>
            <input
              type="text"
              id="lastName"
              className="form-input"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              type="email"
              id="email"
              className="form-input"
              placeholder="example@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="location" className="form-label">
              Location
            </label>
            <input
              type="text"
              id="location"
              className="form-input"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone" className="form-label">
              Phone
            </label>
            <input
              type="number"
              id="phone"
              className="form-input"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </div>
          <div className="form-group password-input">
            <label htmlFor="password" className="form-label">
              Password
            </label>
            <div className="password-input-wrapper">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                className="form-input"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <span
                className={`password-toggle-icon ${showPassword ? "show" : ""}`}
                onClick={togglePasswordVisibility}
              >
                {showPassword ? "Hide" : "Show"}
              </span>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="type" className="form-label">
              Role
            </label>
            <select
              id="type"
              className="form-input"
              value={type}
              onChange={(e) => setType(e.target.value)}
              required
            >
              <option value="employee">Employee</option>
              <option value="employer">Employer</option>
            </select>
          </div>
          <button
            disabled={pending}
            type="submit"
            className="create-account-button"
          >
            {pending ? <Loader /> : <span>Create Account</span>}
          </button>{" "}
          {success && (
            <p style={{ color: "green" }}>
              User registered! Please check your email to verify.
            </p>
          )}
          {error && (
            <p style={{ color: "red" }}>
              An error occurred. Please ensure this email is not already
              registered on the platform and try again.
            </p>
          )}
          <br></br>
          <p className="login-link">
            Have an account already? <a href="/Login">Login</a>
          </p>
        </form>
      </div>
    </div>
  );
};

export default CreateAccountPage;
