import React from 'react';
import caro7 from '../assets/caro7.jpg';
import caro4 from '../assets/caro4.jfif';
import caro8 from '../assets/caro8.jfif';
import caro9 from '../assets/caro9.jfif';
import caro10 from '../assets/caro10.jfif';
import caro11 from '../assets/caro11.jfif';


const Testimonials = () => {
  

  const testimonials = [
    {
      image: caro7,
      name: 'Samuel Mbua',
      position: 'Designer  ',
      text: '237 JOBS is the best Job resource out here in cameroon, It is so user friendly and has high quality job listings.'
    },
    {
      image: caro4,
      name: 'Jane Ayuk',
      position: 'Teacher',
      text: '237 JOBS has exceeded all my expectations. It is easy to use and has helped me secure a better paying job with better benefits.'
    },
    {
      image: caro8,
      name: 'Mbah Roland',
      position: 'IT Expert',
      text: 'I got my best ever paid job from the 237 JOBS platform, All I had to do was to make a job request and in no time an employer reached out to me.'
    },
    {
      image: caro9,
      name: 'Jennifer Epie',
      position: 'Sales Assistant',
      text: 'This is the second time I used 237 JOBS and I found the perfect Job for me, thanks very much I am so grateful.'
    },
    {
      image: caro10,
      name: 'Ruth Enongene',
      position: 'Nurse',
      text: 'Thanks to 237 Jobs I finally got a job after so many months of a job physical job seeking search.'
    },
    {
      image: caro11,
      name: 'Egbe James',
      position: 'Accountant',
      text: '237 jobs is the most reliable job resource, no spams, no junks and no scams. All jobs are authentic and very reliable. Thank you for giving the opportuinity to get a job on your platform. '
    },
    
  ];

 

  return (
    <section className="testimonial-section">
      <h1 className='testimony-header'>What Our Members Say</h1>
      <p className='testimony-text'>Hear from people who love using our platform.</p> <br></br>
      <div className="testimonial-container">
        {testimonials.map((testimonial, index) => (
          <div className="testimonial-card" key={index}>
            <img src={testimonial.image} alt={testimonial.name} className="testimonial-image" />
            <h3 className="testimonial-name">{testimonial.name}</h3>
            <p className="testimonial-position">{testimonial.position}</p>
            <p className="testimonial-text">{testimonial.text}</p>
          </div>
        ))}
      </div>
      
        
    </section>
  );
};

export default Testimonials;