import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine, faBook } from "@fortawesome/free-solid-svg-icons";
import logo from "../assets/logo.jpg";
import axios from "axios";
import { API } from "../config";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "./Loader";

const PricingPage = () => {
  const [subscriptionPlans, setSubscriptionPlans] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selected, setSelected] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    const getSubPlans = async () => {
      try {
        const subscriptions = await axios.get(
          `${API}/api/subscription-plans/plans`
        );
        setSubscriptionPlans(subscriptions.data);
      } catch (error) {
        console.log("error==: ", error);
      }
    };
    getSubPlans();
  }, []);

  const initiateUserSubscription = async (subscriptionPlanId) => {
    setSelected(subscriptionPlanId);
    setIsSubmitting(true);
    try {
      let user = localStorage.getItem("23jobs-user");
      if (!user) {
        navigate("/login");
      }
      user = JSON.parse(user);
      const response = await axios.post(
        `${API}/api/user-subscriptions/initiate`,
        {
          userId: user.id,
          subscriptionPlanId,
          token: user.originToken,
        },
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
      const newTab = window.open("", "_blank");
      newTab.location.href = response.data.link;
    } catch (error) {
      if (error.status === 401) {
        navigate("/login");
      }
      if (error.status === 403) {
        toast.info(error?.response?.data?.message);
      }
      console.log("error", error);
    }
    setIsSubmitting(false);
    setSelected("");
  };

  return (
    <>
      {subscriptionPlans && (
        <div className="pricing-page">
          <h1 className="pricing-title">Choose Your Plan</h1>
          <p className="pricing-description">
            Let's choose the package that is best for you and explore it happily
            and cheerfully.
          </p>
          <div className="w-full flex justify-center pt-4">
            <div className="grid md:grid-cols-2 lg:grid-cols-3 w-fit justify-center gap-8">
              <div className="pricing-plan">
                <div className="pricing-plan-icon">
                  <img src={logo} alt="Presenter" />
                </div>
                <h2 className="pricing-plan-title">
                  {subscriptionPlans[0]?.name}
                </h2>
                <ul className="pricing-plan-features">
                  <li>
                    <FontAwesomeIcon icon={faChartLine} />
                    <span>30 Days Unlimited Job Listings</span>
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faChartLine} />
                    <span>30 Days Unlimited Job request</span>
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faChartLine} />
                    <span>Variety of Jobs</span>
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faChartLine} />
                    <span>Standard Subscription</span>
                  </li>
                </ul>
                <div className="pricing-plan-price">
                  {Math.floor(subscriptionPlans[0]?.price)} frs/month
                </div>
                <button
                  className="pricing-plan-button"
                  disabled={isSubmitting}
                  onClick={() =>
                    initiateUserSubscription(subscriptionPlans[0]?.id)
                  }
                >
                  {isSubmitting && selected === subscriptionPlans[0]?.id ? (
                    <Loader />
                  ) : (
                    "Select"
                  )}
                </button>
              </div>
              <div className="pricing-plan">
                <div className="pricing-plan-icon">
                  <img src={logo} alt="Presenter" />
                </div>
                <h2 className="pricing-plan-title">
                  {subscriptionPlans[1]?.name}
                </h2>
                <ul className="pricing-plan-features">
                  <li>
                    <FontAwesomeIcon icon={faChartLine} />
                    <span>180 Days Unlimited Job Listing Access</span>
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faChartLine} />
                    <span>180 Days Unlimited Job request</span>
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faChartLine} />
                    <span>Unlimited Job Offers</span>
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faChartLine} />
                    <span>Advanced Subscription</span>
                  </li>
                </ul>
                <div className="pricing-plan-price">
                  {" "}
                  {Math.floor(subscriptionPlans[1]?.price)} frs/six months
                </div>
                <button
                  className="pricing-plan-button"
                  disabled={isSubmitting}
                  onClick={() =>
                    initiateUserSubscription(subscriptionPlans[1]?.id)
                  }
                >
                  {isSubmitting && selected === subscriptionPlans[1]?.id ? (
                    <Loader />
                  ) : (
                    "Select"
                  )}
                </button>
              </div>
              <div className="pricing-plan">
                <div className="pricing-plan-icon">
                  <img src={logo} alt="Presenter" />
                </div>
                <h2 className="pricing-plan-title">
                  {subscriptionPlans[2]?.name}
                </h2>
                <ul className="pricing-plan-features">
                  <li>
                    <FontAwesomeIcon icon={faChartLine} />
                    <span>1 Year Unlimited Job Listing Access</span>
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faChartLine} />
                    <span>Unlimited request/year</span>
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faChartLine} />
                    <span>Unlimited Job Offers</span>
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faChartLine} />
                    <span>Premium Subscription</span>
                  </li>
                </ul>
                <div className="pricing-plan-price">
                  {" "}
                  {Math.floor(subscriptionPlans[2]?.price)} frs/yr
                </div>
                <button
                  className="pricing-plan-button"
                  disabled={isSubmitting}
                  onClick={() =>
                    initiateUserSubscription(subscriptionPlans[2]?.id)
                  }
                >
                  {isSubmitting && selected === subscriptionPlans[2]?.id ? (
                    <Loader />
                  ) : (
                    "Select"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PricingPage;
