import React from "react";

const IntroSection = ({ title, children }) => {
  return (
    <div className="intro-section">
      <div className="intro-overlay">
        <h1 className="intro-title">{title}</h1>
        {children}
      </div>
    </div>
  );
};

export default IntroSection;
