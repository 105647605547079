import React, { useEffect, useRef } from "react";

// import AvailableJobs from "./AvailableJobs";
import Card from "../components/Card";
import Search from "../components/Search";
import Testimonial from "../components/Testimonial";
import Partners from "../components/Partners";
import GetApp from "../components/GetApp";
import Pricing from "../components/Pricing";
import Team from "../components/Team";
import RequestedJobsSection from "../components/RequestedJobsSection";
import Seekers from "../components/premium";
import Commu from "../components/community";

const Home = () => {
  const sectionRef = useRef(null);
  useEffect(() => {
    const scrollEffect = () => {
      sectionRef.current = document.getElementById("subscription-section");
      if (window.location.href.includes("#")) {
        scrollToSection();
      } else {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    };
    return () => scrollEffect();
  }, []);

  const scrollToSection = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <>
      <Search />
      <Card />
      <Testimonial />
      {/* <AvailableJobs /> */}
      <div id="subscription-section">
        <Pricing />
      </div>
      <Seekers />
      <RequestedJobsSection />
      <GetApp />
      {/* <AvailableJobs /> */}
      {/* <Team /> */}
      <Partners />
      <Commu />
    </>
  );
};

export default Home;
