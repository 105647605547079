import React from 'react';
import '../App.css';
import { Link } from 'react-router-dom';


function Commu() {
    return (
<div className="h-full  px-3 w-full bg-[#ffffff] -mt-5 flex justify-center text-white  overflow-x-hidden">
          <div className="w-full max-w-screen-md pt-10 pb-20 space-y-7">
                <h3 class='text-[#008000]'> Join Our communities </h3>
                
<p class="mb-3 text-black">Join our whatsapp communities and Channel to receive daily job postings.</p>
<p class="mb-3 text-black ">Our communities Comprises of over 2500+ individuals.</p>
<br></br>
<br></br>
<div className='block'>
    <div>
<Link to='https://chat.whatsapp.com/H19yEaVj5PMEYEXdQUGZsj' className='find1 text-white bg-[#008000]'><button><b>Join Our Communities</b></button></Link></div>
<div className='wacom'><Link to='https://whatsapp.com/channel/0029VabvXjQL7UVM54TO921l' className='wacom find1 text-white bg-[#008000]'><button><b>Follow Our Channel</b></button></Link></div>




</div>
          </div>
          </div>



    );
};
export default Commu;


