import React from "react";
import {
  WhatsappShareButton,
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";
import {
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
} from "react-share";

const SocialShare = (props) => {
  return (
    <div>
      <div className="space-x-1">
        <WhatsappShareButton url={props.url}>
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
        <FacebookShareButton url={props.url}>
          <FacebookIcon size={32} round />
        </FacebookShareButton>
        <TwitterShareButton url={props.url}>
          <TwitterIcon size={32} round />
        </TwitterShareButton>
        <LinkedinShareButton url={props.url}>
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>
      </div>
    </div>
  );
};

export default SocialShare;
