import React, { useState, useEffect } from "react";
import IntroSection from "../components/Intropage";
import { useParams } from "react-router-dom";
import { BiSearch } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { searchJobs } from "../redux-store/feature/job/jobSlice";
import JobComponent from "../components/JobComponent";
const JobCards = () => {
  const { jobType } = useParams();
  const dispatch = useDispatch();
  const jobs = useSelector((state) => state.job.jobs);
  const page = useSelector((state) => state.job.page);
  const pages = useSelector((state) => state.job.pages);
  const limit = useSelector((state) => state.job.limit);
  const [query, serQuery] = useState("");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    const listJobs = async () => {
      if (query && jobType) {
        dispatch(searchJobs({ jobType, query, limit: 6, page: 1 }));
      } else {
        dispatch(searchJobs({ jobType, limit: 6, page: 1 }));
      }
    };
    listJobs();
  }, []);

  const handleSearch = (value) => {
    if (value !== "") {
      dispatch(searchJobs({ jobType, query, limit: 6, page: 1 }));
    } else {
      dispatch(searchJobs({ jobType, limit: 6, page: 1 }));
    }
  };
  const loadJobs = (page) => {
    if (query !== "") {
      dispatch(searchJobs({ jobType, query, limit: 6, page }));
    } else {
      dispatch(searchJobs({ jobType, limit: 6, page }));
    }
  };

  return (
    <div>
      <IntroSection title={`${jobType} Jobs`}>
        <div
          style={{ display: "flex", width: "100%", justifyContent: "center" }}
        >
          <div className="search-container">
            <input
              type="text"
              placeholder="Search..."
              value={query}
              onChange={(e) => {
                serQuery(e.target.value);
                handleSearch(e.target.value);
              }}
            />

            <button onClick={() => handleSearch()}>
              <BiSearch />
            </button>
          </div>
        </div>
      </IntroSection>
      <section className="job-cards-section">
        <div className="md:px-4 w-full">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            <JobComponent jobs={jobs} />
          </div>
        </div>

        {jobs.length === 0 ? (
          <div style={{ textAlign: "center", width: "100%" }}>
            Search not found
          </div>
        ) : (
          <div className="mt-[5rem] w-full flex items-center justify-center">
            <button
              disabled={page <= 1}
              onClick={() => loadJobs(page - 1)}
              style={{ marginRight: "1rem" }}
              class="flex items-center hover:cursor-pointer py-2 px-3 rounded font-medium select-none border text-gray-900 bg-white transition-colors hover:border-green-600 hover:bg-green-600 hover:text-white"
            >
              ⪻ Previous
            </button>
            <div>
              {" "}
              {page} / {pages}
            </div>
            <button
              disabled={page >= pages}
              onClick={() => loadJobs(page + 1)}
              class="flex items-center py-2 ml-4 hover:cursor-pointer px-3 rounded font-medium select-none border text-gray-900  bg-white transition-colors hover:border-green-600 hover:bg-green-600 hover:text-white "
            >
              Next ⪼
            </button>
          </div>
        )}
      </section>
    </div>
  );
};

export default JobCards;

// // AvailableSection.js
// import React, { useState, useEffect } from 'react';
// import caro1 from '../assets/caro1.jpg';
// import caro2 from '../assets/caro2.jpg';
// import caro3 from '../assets/caro3.jpg';
// import { Link } from 'react-router-dom';
// import Introlayout from "../components/Introlayout";

// const AvailableSection = () => {
//   <Introlayout title="about us"></Introlayout>

//   const [currentIndex, setCurrentIndex] = useState(0);
//   const images = [caro1, caro2, caro3, caro2, caro1, caro3];
//   const jobData = [
//     {
//       title: 'Software Engineer',
//       description: 'We are looking for a talented software engineer to join our team.'
//     },
//     {
//       title: 'UI/UX Designer',
//       description: 'Seeking a creative and experienced UI/UX designer to enhance our products.'
//     },
//     {
//       title: 'Marketing Specialist',
//       description: 'Opportunity for a marketing professional to drive our brand and growth.'
//     },
//     {
//         title: 'Data Analyst',
//         description: 'Opportunity for a marketing professional to drive our brand and growth.'
//       },
//       {
//         title: 'Machine Learning Engineer',
//         description: 'Opportunity for a marketing professional to drive our brand and growth.'
//       },
//       {
//         title: 'DevOps Engineer',
//         description: 'Opportunity for a marketing professional to drive our brand and growth.'
//       }
//   ];

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
//     }, 5000);

//     return () => clearInterval(interval);
//   }, [images.length]);

//   return (
//     <div
//       className="available-section"
//       style={{
//         backgroundImage: `url(${images[currentIndex]})`,
//         backgroundSize: 'cover',
//         backgroundPosition: 'center'
//       }}
//     >
//       <div className="available-content">
//         <h2>{jobData[currentIndex].title}</h2>
//         <p>{jobData[currentIndex].description}</p>
//         <Link to="/signup" className="see-more-btn">
//         See More
//         </Link>

//       </div>
//     </div>

//   );

// };

// export default AvailableSection;
