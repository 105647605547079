import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import jobService from "./jobService";

const initialState = {
  jobs: [],
  page: 1,
  pages: 1,
  limit: 12,
};

export const getJobs = createAsyncThunk("job/getJobs", async () => {
  return await jobService.getJobs();
});
export const searchJobs = createAsyncThunk("job/search", async (query) => {
  return await jobService.searchJobs(query);
});

export const jobSlice = createSlice({
  name: "job",
  initialState,
  reducers: {
    reset: (state) => {
      state.jobs = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getJobs.pending, (state, action) => {
        // console.log("pend", action);
      })
      .addCase(getJobs.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.jobs = action.payload.jobs;
        }
      })
      .addCase(getJobs.rejected, (state, action) => {
        // console.log("actions", action);
        // state.jobs = null;
      })
      .addCase(searchJobs.pending, (state, action) => {
        // console.log("pend", action);
      })
      .addCase(searchJobs.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.jobs = action.payload.jobs.data;
          state.limit = action.payload.jobs.meta.limit;
          state.page = action.payload.jobs.meta.page;
          state.pages = action.payload.jobs.meta.pages;
        }
      })
      .addCase(searchJobs.rejected, (state, action) => {
        // console.log("actions", action);
        // state.jobs = null;
      });
  },
});

export default jobSlice.reducer;
export const { reset } = jobSlice.actions;
