import React, { useState, useEffect } from "react";
import Introlayout from "../components/Introlayout";
import axios from "axios";
import { API } from "../config";
import { toast } from "react-toastify";
const ContactForm = () => {
  const [values, setValues] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });
  const [pedding, setPending] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Implement login logic here

    setPending(true);
    setError(false);
    setSuccess(false);
    try {
      await axios.post(`${API}/api/users/contact-email`, values);
      toast.success(
        "We have received your email and our team will get back to you shortly. Thank you!"
      );
      setSuccess(true);
    } catch (error) {
      console.log("error: ", error);
      setError(true);
      // setError(error?.response?.data.message);
    }
    setPending(false);
  };
  return (
    <>
      <Introlayout title="contact us"></Introlayout>
      <br></br>
      <section className="contact-section h-fit py-5">
        <div className="contact-form">
          <h2 className="text-center">Get In Touch With Us</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Your Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={values.name}
                onChange={handleChange}
                placeholder="Enter your name"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Your Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                placeholder="example@example.com"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Your Phone</label>
              <input
                type="phone"
                id="phone"
                name="phone"
                value={values.phone}
                onChange={handleChange}
                placeholder="Enter your phone number"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Your Subject</label>
              <input
                type="text"
                id="subject"
                name="subject"
                value={values.subject}
                onChange={handleChange}
                placeholder="Enter your subject"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Your Message</label>
              <textarea
                id="message"
                name="message"
                value={values.message}
                onChange={handleChange}
                placeholder="Enter your message"
                required
              ></textarea>
            </div>

            <div className="flex justify-center w-full px-12">
              <button className="w-full" type="submit" disabled={pedding}>
                Submit
              </button>
            </div>
            {/* {success && (
              <p style={{ color: "green" }}>
                Please checkout your email acount. A new verification link sent!
              </p>
            )} */}
            {error && (
              <p style={{ color: "red" }}>
                An error occurred. Please try again
              </p>
            )}
          </form>
        </div>
      </section>
    </>
  );
};

export default ContactForm;
