import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Introlayout from "../components/Introlayout";

const JobCards = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const jobData = [
    {
      id: 1,
      jobTitle: "Software Engineer",
      jobDescription:
        "Develop and maintain robust software systems for our growing company.",
      viewLink: "#",
    },
    {
      id: 2,
      jobTitle: "UI/UX Designer",
      jobDescription:
        "Create visually appealing and user-friendly interfaces for our web and mobile applications.",
      viewLink: "#",
    },
    {
      id: 3,
      jobTitle: "Digital Marketing Specialist",
      jobDescription:
        "Develop and implement effective digital marketing strategies to promote our products and services.",
      viewLink: "#",
    },
    {
      id: 4,
      jobTitle: "Data Analyst",
      jobDescription:
        "Analyze and interpret complex data to provide meaningful insights for business decision-making.",
      viewLink: "#",
    },
    {
      id: 4,
      jobTitle: "Data Analyst",
      jobDescription:
        "Analyze and interpret complex data to provide meaningful insights for business decision-making.",
      viewLink: "#",
    },
    {
      id: 6,
      jobTitle: "Content Writer",
      jobDescription:
        "Create engaging and informative content for our website, blog, and social media channels.",
      viewLink: "#",
    },
    {
      id: 4,
      jobTitle: "Data Analyst",
      jobDescription:
        "Analyze and interpret complex data to provide meaningful insights for business decision-making.",
      viewLink: "#",
    },
    {
      id: 5,
      jobTitle: "Project Manager",
      jobDescription:
        "Coordinate and manage cross-functional teams to ensure the successful delivery of projects.",
      viewLink: "#",
    },
    {
      id: 6,
      jobTitle: "Content Writer",
      jobDescription:
        "Create engaging and informative content for our website, blog, and social media channels.",
      viewLink: "#",
    },
  ];

  return (
    <div>
      <Introlayout title="Available Jobs" />
      <section className="job-cards-section">
        <div className="md:px-4 w-full">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            {jobData.map((job) => (
              <div key={job.id} className="job-card">
                <h3 className="job-title">{job.jobTitle}</h3>
                <p className="job-description">{job.jobDescription}</p>
                <div className="card-actions">
                  <a href={job.viewLink} className="view-button">
                    View
                  </a>
                  <button className="share-button">
                    <FontAwesomeIcon icon={faShareAlt} />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>

        <br></br>
        <Link to="#">
          <button className="see-more-link">See more</button>
        </Link>
      </section>
    </div>
  );
};

export default JobCards;
