import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { API } from "../config";
import logo from "../assets/logo.jpg";
import pro from "../assets/pro.jpg";

import { BiMenu } from "react-icons/bi";
import { CgClose } from "react-icons/cg";

const Navbar = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(null);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleLanguageDropdown = () => {
    setIsLanguageDropdownOpen(!isLanguageDropdownOpen);
  };

  const handlePostJobClick = () => {
    navigate("/post-jobs");
  };

  const handleRequestJobClick = () => {
    navigate("/request-jobs");
  };

  const handleLanguageChange = (lang) => {
    console.log(`Changing language to ${lang}`);
    setIsLanguageDropdownOpen(false);
  };

  useEffect(() => {
    const getUser = async () => {
      try {
        let localUser = localStorage.getItem("23jobs-user");
        localUser = JSON.parse(localUser);

        const refreshedUser = await axios.post(
          `${API}/api/users/user`,
          {
            userId: localUser.id,
            token: localUser.originToken,
          },
          {
            headers: { Authorization: `Bearer ${localUser.token}` },
          }
        );
        localStorage.removeItem("23jobs-user");
        localStorage.setItem(
          "23jobs-user",
          JSON.stringify(refreshedUser.data.user)
        );

        setUser(refreshedUser.data.user);
      } catch (error) {
        // if (error.status === 401) {
        //   navigate("/login");
        // }
      }
    };
    getUser();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.closest(".dropdown") === null) {
        setIsDropdownOpen(false);
        setIsLanguageDropdownOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [navigate]);

  return (
    <>
      <nav className="navbar-mobile">
        <div className="navbar-left">
          <img src={logo} alt="Logo" className="navbar-logo" />
        </div>
        <div className="menu">
          {open ? (
            <CgClose
              onClick={() => {
                setOpen(false);
              }}
            />
          ) : (
            <BiMenu
              onClick={() => {
                setOpen(true);
                setTimeout(() => {
                  setOpen(false);
                }, 5000);
              }}
            />
          )}
          {open ? (
            <div className="responsive-nav">
              <div className="navbar-center">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link to="/">HOME</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/jobs">JOBS</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/requested-jobs">REQUEST</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/about">ABOUT</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/ContactUs">CONTACT</Link>
                  </li>
                  <li className="nav-item dropdown">
                    <div className={`dropdown ${isDropdownOpen ? "show" : ""}`}>
                      <button
                        className="dropdown-button"
                        onClick={toggleDropdown}
                      >
                        <i className="fas fa-plus">+</i>
                      </button>
                      <div className="dropdown-content">
                        <Link to="/postjobs" onClick={handlePostJobClick}>
                          Post Jobs
                        </Link>
                        <Link to="/request-job" onClick={handleRequestJobClick}>
                          Request Jobs
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li
                    className={`nav-item dropdown ${
                      isLanguageDropdownOpen ? "open" : ""
                    }`}
                    onClick={toggleLanguageDropdown}
                  >
                    <span className="english">English</span>
                    {isLanguageDropdownOpen && (
                      <ul className="dropdown-menu">
                        <li>
                          <Link
                            to="#"
                            onClick={() => handleLanguageChange("fr")}
                          >
                            French
                          </Link>
                        </li>
                      </ul>
                    )}
                  </li>
                </ul>
              </div>
              <div className="navbar-right">
                {user == null ? (
                  <Link to="/login" className="nav-item btn btn-primary">
                    Sign in
                  </Link>
                ) : (
                  <div className="profile-icon">
                    <Link to="/Dashboard">
                      <img src={pro} alt="Profile" />
                    </Link>
                  </div>
                )}
              </div>
            </div>
          ) : undefined}
        </div>
      </nav>

      <nav className="navbar">
        <div className="navbar-left">
          <img src={logo} alt="Logo" className="navbar-logo" />
        </div>
        <div className="navbar-center">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link to="/">HOME</Link>
            </li>
            <li className="nav-item">
              <Link to="/jobs">JOBS</Link>
            </li>
            <li className="nav-item">
              <Link to="/requested-jobs">REQUEST</Link>
            </li>
            <li className="nav-item">
              <Link to="/about">ABOUT</Link>
            </li>
            <li className="nav-item">
              <Link to="/ContactUs">CONTACT</Link>
            </li>
            <li className="nav-item dropdown">
              <div className={`dropdown ${isDropdownOpen ? "show" : ""}`}>
                <button className="dropdown-button" onClick={toggleDropdown}>
                  <i className="fas fa-plus">+</i>
                </button>
                <div className="dropdown-content">
                  <Link to="/Postjobs" onClick={handlePostJobClick}>
                    Post Jobs
                  </Link>
                  <Link to="/request-job" onClick={handleRequestJobClick}>
                    Request Jobs
                  </Link>
                </div>
              </div>
            </li>
            <li
              className={`nav-item dropdown ${
                isLanguageDropdownOpen ? "open" : ""
              }`}
              onClick={toggleLanguageDropdown}
            >
              <span className="english">English</span>
              {isLanguageDropdownOpen && (
                <ul className="dropdown-menu">
                  <li>
                    <Link to="#" onClick={() => handleLanguageChange("fr")}>
                      French
                    </Link>
                  </li>
                </ul>
              )}
            </li>
          </ul>
        </div>
        <div className="navbar-right">
          {user == null ? (
            <Link to="/login" className="nav-item btn btn-primary">
              Sign in
            </Link>
          ) : (
            <div className="profile-icon">
              <Link to="/Dashboard">
                <img src={pro} alt="Profile" />
              </Link>
            </div>
          )}
        </div>
      </nav>
    </>
  );
};

export default Navbar;
