// Reset Password React Component
import React, { useState, useEffect } from "react";
import axios from "axios";
import { API } from "../config";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [pending, setPending] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setPending(true);
    setError("");
    setSuccess(false);
    try {
      await axios.post(`${API}/api/users/reset-password-request`, {
        email: email,
      });

      setSuccess(true);
    } catch (error) {
      console.log("error: ", error);
      setError(error?.response?.data?.message);
    }
    setPending(false);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="reset-password-container">
      <div className="reset-password-card">
        <h2>Reset Password</h2> <br></br>
        <br></br>
        <form className="reset-password-form" onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button type="submit" disabled={pending}>
            {pending ? <Loader /> : <span>Request password reset</span>}
          </button>
          {success && (
            <p style={{ color: "green" }}>
              Please checkout your email. A password reset link has been sent!.
            </p>
          )}
          {error && <p style={{ color: "red" }}>{error}</p>}
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
