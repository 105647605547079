import React, { useState, useEffect } from "react";
import Introlayout from "../components/Introlayout";
import axios from "axios";
import { API } from "../config";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import moment from "moment";
export default function JobDetails() {
  const [user, setUser] = useState("");
  const [rows, setRows] = useState(5);
  const [isLoading, setLoading] = useState(true);
  const [title, setJobTitle] = useState("");
  const [company, setCompany] = useState("");
  const [location, setLocation] = useState("");
  const [salary, setSalary] = useState("");
  const [description, setDescription] = useState("");
  const [jobType, setJobType] = useState("");
  const [employmentType, setEmploymentType] = useState("");
  const [status, setStatus] = useState("active");
  const [duration, setDuration] = useState("");
  const [pending, setPending] = useState(false);
  const [applications, setApplications] = useState([]);
  const [job, setJob] = useState(null);
  const { id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    const getUser = async () => {
      try {
        let user = localStorage.getItem("23jobs-user");
        if (!user) {
          navigate("/login");
        }
        user = JSON.parse(user);
        setUser(user);

        const response = await axios.post(
          `${API}/api/job`,
          {
            id: id,
            userId: user.id,
            token: user.originToken,
          },
          {
            headers: { Authorization: `Bearer ${user.token}` },
          }
        );

        try {
          await axios.post(
            `${API}/api/user-subscriptions/user/subscribed`,
            {
              userId: user.id,
              token: user.originToken,
            },
            {
              headers: { Authorization: `Bearer ${user.token}` },
            }
          );
        } catch (error) {
          if (user.id !== response.data.userId) {
            toast.warn("Unauthorized. You have no active subscription!");
            navigate("/");
          }
        }

        const response2 = await axios.get(
          `${API}/api/job-applications/${id}`,
          {
            id: id,
            userId: user.id,
            token: user.originToken,
          },
          {
            headers: { Authorization: `Bearer ${user.token}` },
          }
        );

        setApplications(response2.data);

        setJobTitle(response.data.title);
        setCompany(response.data.company);
        setLocation(response.data.location);
        setSalary(response.data.salary);
        setDescription(response.data.description);
        setJobType(response.data.jobType);
        setEmploymentType(response.data.employmentType);
        setStatus(response.data.status);
        setDuration(response.data.duration);
        setJob(response.data);
      } catch (error) {
        console.log("error: ", error);
      }
      setLoading(false);
    };
    getUser();

    const intervalId = setInterval(() => {
      const textarea = document.getElementById("dynamicTextarea");
      if (textarea) {
        const placeholderText = textarea?.placeholder;
        const rows = placeholderText.split("\n").length;
        textarea.rows = rows + 10;
      }
    }, 1000);

    // Stop the interval after 5 seconds
    setTimeout(() => {
      clearInterval(intervalId);
      console.log("Interval stopped.");
    }, 5000);
  }, []);

  const apply = async () => {
    navigate(`/apply/${id}`);
  };

  const downloadButton = (fileName) => {
    const link = document.createElement("a");
    link.href = `${API}/api/file/download-resume${fileName}`;
    // link.download = fileName;
    document.body.appendChild(link);
    link.click();
    // document.body.removeChild(link);
  };

  return (
    <>
      {isLoading ? (
        <div className="w-full flex justify-center items-center min-h-[70vh]">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 24 24"
            >
              <g>
                <circle cx="12" cy="3" r="1" fill="currentColor">
                  <animate
                    id="svgSpinners12DotsScaleRotate0"
                    attributeName="r"
                    begin="0;svgSpinners12DotsScaleRotate2.end-0.5s"
                    calcMode="spline"
                    dur="0.6s"
                    keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                    values="1;2;1"
                  />
                </circle>
                <circle cx="16.5" cy="4.21" r="1" fill="currentColor">
                  <animate
                    id="svgSpinners12DotsScaleRotate1"
                    attributeName="r"
                    begin="svgSpinners12DotsScaleRotate0.begin+0.1s"
                    calcMode="spline"
                    dur="0.6s"
                    keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                    values="1;2;1"
                  />
                </circle>
                <circle cx="7.5" cy="4.21" r="1" fill="currentColor">
                  <animate
                    id="svgSpinners12DotsScaleRotate2"
                    attributeName="r"
                    begin="svgSpinners12DotsScaleRotate4.begin+0.1s"
                    calcMode="spline"
                    dur="0.6s"
                    keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                    values="1;2;1"
                  />
                </circle>
                <circle cx="19.79" cy="7.5" r="1" fill="currentColor">
                  <animate
                    id="svgSpinners12DotsScaleRotate3"
                    attributeName="r"
                    begin="svgSpinners12DotsScaleRotate1.begin+0.1s"
                    calcMode="spline"
                    dur="0.6s"
                    keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                    values="1;2;1"
                  />
                </circle>
                <circle cx="4.21" cy="7.5" r="1" fill="currentColor">
                  <animate
                    id="svgSpinners12DotsScaleRotate4"
                    attributeName="r"
                    begin="svgSpinners12DotsScaleRotate6.begin+0.1s"
                    calcMode="spline"
                    dur="0.6s"
                    keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                    values="1;2;1"
                  />
                </circle>
                <circle cx="21" cy="12" r="1" fill="currentColor">
                  <animate
                    id="svgSpinners12DotsScaleRotate5"
                    attributeName="r"
                    begin="svgSpinners12DotsScaleRotate3.begin+0.1s"
                    calcMode="spline"
                    dur="0.6s"
                    keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                    values="1;2;1"
                  />
                </circle>
                <circle cx="3" cy="12" r="1" fill="currentColor">
                  <animate
                    id="svgSpinners12DotsScaleRotate6"
                    attributeName="r"
                    begin="svgSpinners12DotsScaleRotate8.begin+0.1s"
                    calcMode="spline"
                    dur="0.6s"
                    keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                    values="1;2;1"
                  />
                </circle>
                <circle cx="19.79" cy="16.5" r="1" fill="currentColor">
                  <animate
                    id="svgSpinners12DotsScaleRotate7"
                    attributeName="r"
                    begin="svgSpinners12DotsScaleRotate5.begin+0.1s"
                    calcMode="spline"
                    dur="0.6s"
                    keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                    values="1;2;1"
                  />
                </circle>
                <circle cx="4.21" cy="16.5" r="1" fill="currentColor">
                  <animate
                    id="svgSpinners12DotsScaleRotate8"
                    attributeName="r"
                    begin="svgSpinners12DotsScaleRotatea.begin+0.1s"
                    calcMode="spline"
                    dur="0.6s"
                    keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                    values="1;2;1"
                  />
                </circle>
                <circle cx="16.5" cy="19.79" r="1" fill="currentColor">
                  <animate
                    id="svgSpinners12DotsScaleRotate9"
                    attributeName="r"
                    begin="svgSpinners12DotsScaleRotate7.begin+0.1s"
                    calcMode="spline"
                    dur="0.6s"
                    keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                    values="1;2;1"
                  />
                </circle>
                <circle cx="7.5" cy="19.79" r="1" fill="currentColor">
                  <animate
                    id="svgSpinners12DotsScaleRotatea"
                    attributeName="r"
                    begin="svgSpinners12DotsScaleRotateb.begin+0.1s"
                    calcMode="spline"
                    dur="0.6s"
                    keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                    values="1;2;1"
                  />
                </circle>
                <circle cx="12" cy="21" r="1" fill="currentColor">
                  <animate
                    id="svgSpinners12DotsScaleRotateb"
                    attributeName="r"
                    begin="svgSpinners12DotsScaleRotate9.begin+0.1s"
                    calcMode="spline"
                    dur="0.6s"
                    keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                    values="1;2;1"
                  />
                </circle>
                <animateTransform
                  attributeName="transform"
                  dur="6s"
                  repeatCount="indefinite"
                  type="rotate"
                  values="360 12 12;0 12 12"
                />
              </g>
            </svg>
            loading...
          </div>
        </div>
      ) : (
        <div>
          <Helmet>
            <title>{title} | 237 Jobs</title>
            <meta name="description" content={description} />
          </Helmet>
          <Introlayout title={title}></Introlayout>
          <div className="job-posting-container">
            <div className="job-posting-form-preview">
              {/* Job Posting Preview */}
              <div className="job-posting-preview">
                <h2 className="job-posting-preview-title">
                  {title || "Job Title"}
                </h2>
                <div className="job-posting-preview-info">
                  <div className="job-posting-preview-info-item">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="job-posting-preview-icon"
                    >
                      <rect width="20" height="14" x="2" y="7" rx="2" ry="2" />
                      <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16" />
                    </svg>
                    <span>{company || "Company Name"}</span>
                  </div>
                  <div className="job-posting-preview-info-item">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="job-posting-preview-icon"
                    >
                      <path d="M20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z" />
                      <circle cx="12" cy="10" r="3" />
                    </svg>
                    <span>{location || "Job Location"}</span>
                  </div>
                  <div className="job-posting-preview-info-item">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <g fill="none" fill-rule="evenodd">
                        <path d="m12.594 23.258l-.012.002l-.071.035l-.02.004l-.014-.004l-.071-.036q-.016-.004-.024.006l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.016-.018m.264-.113l-.014.002l-.184.093l-.01.01l-.003.011l.018.43l.005.012l.008.008l.201.092q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.003-.011l.018-.43l-.003-.012l-.01-.01z" />
                        <path
                          fill="currentColor"
                          d="M10.975 3.002a1 1 0 0 1-.754 1.196a8 8 0 0 0-.583.156a1 1 0 0 1-.59-1.911q.36-.112.73-.195a1 1 0 0 1 1.197.754m2.05 0a1 1 0 0 1 1.196-.754c4.454 1.01 7.78 4.992 7.78 9.752c0 5.523-4.478 10-10 10c-4.761 0-8.743-3.325-9.753-7.779a1 1 0 0 1 1.95-.442a8 8 0 1 0 9.58-9.58a1 1 0 0 1-.753-1.197M6.614 4.72a1 1 0 0 1-.053 1.414q-.222.205-.427.426A1 1 0 0 1 4.668 5.2q.255-.276.532-.533a1 1 0 0 1 1.414.053M12 6a1 1 0 0 1 1 1v4.586l2.707 2.707a1 1 0 0 1-1.414 1.414l-3-3A1 1 0 0 1 11 12V7a1 1 0 0 1 1-1M3.693 8.388a1 1 0 0 1 .661 1.25a8 8 0 0 0-.156.583a1 1 0 0 1-1.95-.442q.084-.37.195-.73a1 1 0 0 1 1.25-.661"
                        />
                      </g>
                    </svg>
                    <span>{duration || "Application dateline"}</span>
                  </div>
                  <div className="job-posting-preview-info-item">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="job-posting-preview-icon"
                    >
                      <rect width="18" height="18" x="3" y="4" rx="2" ry="2" />
                      <line x1="16" x2="16" y1="2" y2="6" />
                      <line x1="8" x2="8" y1="2" y2="6" />
                      <line x1="3" x2="21" y1="10" y2="10" />
                      <path d="m9 16 2 2 4-4" />
                    </svg>
                    <span>{jobType || "Job Type"}</span>
                  </div>
                  <div className="job-posting-preview-info-item">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="job-posting-preview-icon"
                    >
                      <path d="M22 12h-4l-3 9L9 3l-3 9H2" />
                    </svg>
                    <span>{employmentType || "Employment Type"}</span>
                  </div>
                  <div className="job-posting-preview-info-item">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="job-posting-preview-icon"
                    >
                      <circle cx="12" cy="12" r="10" />
                      <path d="M12 2v20" />
                      <path d="M12 12h8.5" />
                      <path d="m8.5 8.5-7 7" />
                      <path d="m8.5 15.5 7-7" />
                    </svg>
                    <span>{salary || "Salary Range"}</span>
                  </div>
                </div>
                <div className="job-posting-preview-description">
                  <h3 className="job-posting-preview-description-title">
                    Job Description
                  </h3>
                  <textarea
                    id="dynamicTextarea"
                    rows={rows}
                    class="block p-2.5 w-full bg-white border-none text-gray-700"
                    placeholder={description}
                  ></textarea>
                  {/* <p className="job-posting-preview-description-text">
                    {description || "Job description will appear here..."}
                  </p> */}
                </div>

                {job?.apply && (
                  <button
                    className="form-button"
                    disabled={pending}
                    onClick={() => apply()}
                  >
                    Apply
                  </button>
                )}
              </div>

              {/* Job Posting Form */}
              {user?.id === job?.userId && (
                <div className="job-posting-form">
                  <h3>List of Applicants</h3>
                  {applications.map((app) => (
                    <div
                      key={app?.id}
                      className="job-card"
                      style={{ marginBottom: "1.5rem" }}
                    >
                      <h3 className="job-title">
                        Name: {app?.applicant?.firstName}{" "}
                        {app?.applicant?.lastName}
                      </h3>
                      <p className="job-description">
                        Email: {app?.applicant?.email}
                      </p>
                      <p className="job-description">
                        Number: {app?.applicant?.phone}
                      </p>
                      <p className="job-description">
                        Location: {app?.applicant?.location}
                      </p>
                      <p className="job-description">
                        Applied:{" "}
                        {moment
                          .utc(app?.applicant?.createdAt)
                          .local()
                          .fromNow()}
                      </p>

                      <button
                        className="form-button"
                        disabled={pending}
                        onClick={() => downloadButton(app?.resume.substring(7))}
                      >
                        Download Resume
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
