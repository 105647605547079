import React, { useEffect } from "react";
import Introlayout from "../components/Introlayout";
import RequestedJobsSection from "../components/RequestedJobsSection";

const RequestJobsComponent = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Introlayout title="REQUESTED JOBS"></Introlayout>
      <RequestedJobsSection />
    </div>
  );
};

export default RequestJobsComponent;
