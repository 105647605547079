import React from 'react';
import '../App.css';
import { Link } from 'react-router-dom';


function Seekers() {
    return (
<div className="h-full  px-3 w-full bg-[#008000] -mt-5 flex justify-center text-white  overflow-x-hidden">
          <div className="w-full max-w-screen-md pt-10 pb-20 space-y-7">
                <h3 class=''> Over 2500+ Job Seekers Have Used
                237 Jobs to Find a Better Way to Work </h3>
                       <Link to = "/jobs"> <button class='find'>
                        <b>FIND YOUR DREAM JOB</b></button></Link>
                        <br></br>
                        <br></br>

          </div>
          </div>



    );
};
export default Seekers;


