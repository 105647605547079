import React, { useState, useRef, useEffect } from "react";
import { BiSearch } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { getJobs, searchJobs } from "../redux-store/feature/job/jobSlice";

const HeroSection = () => {
  const [query, setQuery] = useState("");
  const sectionRef = useRef(null);
  const jobs = [
    "Software Engineer",
    "Web Developer",
    "Data Scientist",
    "Product Manager",
    "Project Manager",
    "UI/UX Designer",
  ];
  const dispatch = useDispatch();

  useEffect(() => {
    sectionRef.current = document.getElementById("jobs-section");
  }, []);

  const scrollToSection = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const handleSearch = () => {
    if (query) {
      dispatch(searchJobs({ query, limit: 12, page: 1 }));
    } else {
      dispatch(getJobs());
    }
    scrollToSection();
  };

  return (
    <div className="hero-section">
      <div className="hero-overlay"></div>
      <div className="hero-content ">
        <div className="left-container">
          <h1 className="">Find Your Dream Job</h1>
          <h2>Search for jobs with ease.</h2>
          <p>
            Discover a wide range of job opportunities that match your skills
            and preferences. no-ads, no spam, no scam & no junks. Our team spends 24+ hours/day verifying every job.
          </p>
        </div>
        <div className="right-container ">
          <div className="search-container  w-full">
            <input
              type="text"
              placeholder="Select Job?"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              className="w-full"
            />
            <button onClick={handleSearch}>
              <BiSearch />
            </button>
          </div>
          <p className="text-start">
            Search for your ideal job by title, type, location.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
