import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { getJobs } from "../redux-store/feature/job/jobSlice";
import SocialShare from "./SocialShare";
import axios from "axios";
import { API } from "../config";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import moment from "moment";
const JobComponent = (props) => {
  const [subscribed, setSubscribed] = useState(false);
  const [share, setShare] = useState(false);
  const [id, setJobId] = useState("");
  const [user, setUser] = useState(null);
  const jobs = useSelector((state) => state.job.jobs);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sectionRef = useRef(null);

  useEffect(() => {
    sectionRef.current = document.getElementById("subscription-section");
    const checkSubscription = async () => {
      try {
        let user = localStorage.getItem("23jobs-user");
        if (!user) {
          return;
        }
        user = JSON.parse(user);

        try {
          const refreshedUser = await axios.post(
            `${API}/api/users/user`,
            {
              userId: user.id,
              token: user.originToken,
            },
            {
              headers: { Authorization: `Bearer ${user.token}` },
            }
          );

          localStorage.removeItem("23jobs-user");
          localStorage.setItem(
            "23jobs-user",
            JSON.stringify(refreshedUser.data.user)
          );

          setUser(refreshedUser.data.user);
        } catch (error) {
          // console.log("user: erro");
        }

        await axios.post(
          `${API}/api/user-subscriptions/user/subscribed`,
          {
            userId: user.id,
            token: user.originToken,
          },
          {
            headers: { Authorization: `Bearer ${user.token}` },
          }
        );
        setSubscribed(true);
      } catch (error) {
        console.log("error: ", error.response);
      }
    };
    checkSubscription();
  }, []);
  const scrollToSection = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const viewJob = (jobId) => {
    if (!user) {
      toast.info("You are not authenticated. Please sign in.");
      return;
    }

    const job = jobs.find((job) => job.id === jobId);

    if (!job) {
      toast.error("Job not found.");
      return;
    }

    if (user.id === job.userId || subscribed) {
      navigate(`/job-details/${jobId}`);
    } else {
      toast.info(
        "You do not have an active subscription. Scroll down to the subscriptions section to subscribe."
      );
      if (
        window.location.pathname === "/" ||
        window.location.href.includes("#")
      ) {
        scrollToSection();
      } else {
        navigate("/#subscription-section");
      }
    }
  };

  const formatCreatedAt = (date) => {
    const now = moment();
    const createdAt = moment(date);
    const diffInDays = now.diff(createdAt, "days");

    if (diffInDays < 7) return "Recently";
    if (diffInDays >= 7 && diffInDays < 14) return "1 week ago";
    if (diffInDays >= 14 && diffInDays < 21) return "2 weeks ago";
    if (diffInDays >= 21 && diffInDays < 30) return "3 weeks ago";

    // For dates beyond 30 days, calculate months and years
    const diffInMonths = now.diff(createdAt, "months");
    if (diffInMonths >= 1 && diffInMonths <= 11) {
      return `${diffInMonths} ${diffInMonths === 1 ? "month" : "months"} ago`;
    }

    const diffInYears = now.diff(createdAt, "years");
    return `${diffInYears} ${diffInYears === 1 ? "year" : "years"} ago`;
  };

  return (
    <>
      {props.jobs.map((job) => (
        <div key={job.id} className="job-card">
          <h3 className="job-title">{job.title}</h3>
          <p className="job-description truncteDescription">
            {job.description}
          </p>
          <div className="card-actions">
            <a
              href={job.viewLink}
              className="view-button"
              onClick={() => viewJob(job.id)}
            >
              View
            </a>
            <button className="share-button">
              <span
                className="text-green-500 text-[1rem]"
                style={{ marginRight: ".5rem" }}
              >
                {formatCreatedAt(job.createdAt)}
              </span>
              <span
                onClick={() => {
                  setShare(!share);
                  setJobId(job.id);
                }}
              >
                <FontAwesomeIcon icon={faShareAlt} />
              </span>

              {share && id === job.id && (
                <SocialShare
                  url={`https://237jobs.com/job-details/${job.id}`}
                />
              )}
            </button>
          </div>
        </div>
      ))}
    </>
  );
};

export default JobComponent;
