import React, { useState } from "react";
import "./App.css";
import Home from "./pages/Home";
import Navbar from "./pages/Navbar";
import About from "./pages/About";
import RemoteJobs from "./pages/RemoteJobs";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import logo from "./assets/logo.jpg";
import { HelmetProvider } from "react-helmet-async";
import Resetpassword from "./pages/Resetpassword";
import ContactUs from "./pages/ContactUs";

import AvailableJobs from "./pages/AvailableJobs";

import Footer from "./components/Footer";

import Postjobs from "./pages/Postjobs";
import Requestjobs from "./pages/Requestjobs";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import VerifyEmail from "./pages/verify-email";
import NewToken from "./pages/NewToken";
import NewPassword from "./pages/newpassword";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserJobs from "./pages/UserJobs";
import EditJob from "./pages/EditJob";
import JobDetails from "./pages/JobDetails";
import JobApplication from "./pages/JobApplication";
import YourApplications from "./pages/YourApplications";
import JobsByLocation from "./pages/JobsByLocation";
import MoreJobs from "./pages/MoreJobs";
import RequestJob from "./pages/RequestJob";
import UserJobRequests from "./pages/UserJobRequests";
import EditJobRequest from "./pages/EditJobRequest";

function App() {
  const [loading, setLoading] = useState(true);
  setTimeout(() => {
    setLoading(false);
  }, 2000);

  if (loading) {
    return (
      <div className="loader-container">
        <img src={logo} alt="Logo" className="loader-logo" />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 200 200"
          style={{ height: "100px", marginTop: "-100px" }}
        >
          <circle
            fill="#45FF00"
            stroke="#45FF00"
            stroke-width="15"
            r="15"
            cx="35"
            cy="100"
          >
            <animate
              attributeName="cx"
              calcMode="spline"
              dur="2"
              values="35;165;165;35;35"
              keySplines="0 .1 .5 1;0 .1 .5 1;0 .1 .5 1;0 .1 .5 1"
              repeatCount="indefinite"
              begin="0"
            ></animate>
          </circle>
          <circle
            fill="#45FF00"
            stroke="#45FF00"
            stroke-width="15"
            opacity=".8"
            r="15"
            cx="35"
            cy="100"
          >
            <animate
              attributeName="cx"
              calcMode="spline"
              dur="2"
              values="35;165;165;35;35"
              keySplines="0 .1 .5 1;0 .1 .5 1;0 .1 .5 1;0 .1 .5 1"
              repeatCount="indefinite"
              begin="0.05"
            ></animate>
          </circle>
          <circle
            fill="#45FF00"
            stroke="#45FF00"
            stroke-width="15"
            opacity=".6"
            r="15"
            cx="35"
            cy="100"
          >
            <animate
              attributeName="cx"
              calcMode="spline"
              dur="2"
              values="35;165;165;35;35"
              keySplines="0 .1 .5 1;0 .1 .5 1;0 .1 .5 1;0 .1 .5 1"
              repeatCount="indefinite"
              begin=".1"
            ></animate>
          </circle>
          <circle
            fill="#45FF00"
            stroke="#45FF00"
            stroke-width="15"
            opacity=".4"
            r="15"
            cx="35"
            cy="100"
          >
            <animate
              attributeName="cx"
              calcMode="spline"
              dur="2"
              values="35;165;165;35;35"
              keySplines="0 .1 .5 1;0 .1 .5 1;0 .1 .5 1;0 .1 .5 1"
              repeatCount="indefinite"
              begin=".15"
            ></animate>
          </circle>
          <circle
            fill="#45FF00"
            stroke="#45FF00"
            stroke-width="15"
            opacity=".2"
            r="15"
            cx="35"
            cy="100"
          >
            <animate
              attributeName="cx"
              calcMode="spline"
              dur="2"
              values="35;165;165;35;35"
              keySplines="0 .1 .5 1;0 .1 .5 1;0 .1 .5 1;0 .1 .5 1"
              repeatCount="indefinite"
              begin=".2"
            ></animate>
          </circle>
        </svg>
      </div>
    );
  }

  return (
    <>
      <HelmetProvider>
        <Router>
          <Navbar />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/About" element={<About />} />
            <Route path="/RemoteJobs" element={<RemoteJobs />} />
            <Route path="/Signup" element={<Signup />} />
            <Route path="/Login" element={<Login />} />
            <Route path="/Resetpassword" element={<Resetpassword />} />
            <Route path="/reset-password/:token" element={<NewPassword />} />
            <Route path="/ContactUs" element={<ContactUs />} />
            <Route path="/Card" element={<AvailableJobs />} />
            <Route path="/postjobs" element={<Postjobs />} />
            <Route path="/requested-jobs" element={<Requestjobs />} />
            <Route path="/user-jobs" element={<UserJobs />} />
            <Route path="/user-jobrequests" element={<UserJobRequests />} />
            <Route path="/edit-job/:id" element={<EditJob />} />
            <Route path="/edit-jobrequest/:id" element={<EditJobRequest />} />
            <Route path="/your-applications" element={<YourApplications />} />
            <Route path="/apply/:id" element={<JobApplication />} />
            <Route path="/Dashboard" element={<Dashboard />} />
            <Route path="/verify-email/:token" element={<VerifyEmail />} />
            <Route path="/job-type/:jobType" element={<RemoteJobs />} />
            <Route path="/jobs" element={<MoreJobs />} />
            <Route
              path="/location-jobs/:location"
              element={<JobsByLocation />}
            />
            <Route path="/new-token" element={<NewToken />} />
            <Route path="/job-details/:id" element={<JobDetails />} />
            <Route path="/request-job" element={<RequestJob />} />
          </Routes>
          <ToastContainer />
          <Footer />
        </Router>
      </HelmetProvider>
    </>
  );
}

export default App;
